<div [formGroup]="form.get('meta')">

    <!-- {{form.get('meta').get('limit').value | json}} -->

    <div *ngFor="let key of meta_keys">
        <div [formGroup]="form.get('meta').get(key)" class="grid grid-cols-12 gap-4">

            <ashcorp-field-text
                                [class]="'col-span-' + form.get('meta').get(key).get('width').value"
                                [form]="form.get('meta').get(key)"
                                [label]="form.get('meta').get(key).get('label').value"
                                [show]="form.get('meta').get(key).get('type').value == 'text'"
                                name="control">
            </ashcorp-field-text>

        </div>
    </div>
</div>