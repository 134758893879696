<div [formGroup]="form">
    <mat-form-field style="width:100%;">
        <mat-label *ngIf="show">{{label}}</mat-label>
        <input matInput [formControlName]="name" [placeholder]="label" [matDatepicker]="date" (dateChange)="changeDatePicker()">
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
        <mat-error *ngIf="show && form && form.get(name).hasError('required')">
            {{label}} is <strong>required</strong>
        </mat-error>
    </mat-form-field>
</div>