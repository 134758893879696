import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { SharedService } from 'app/shared/shared.service';

@Component({
  selector: 'ashcorp-field-meta',
  templateUrl: './ashcorp-field-meta.component.html',
  animations: fuseAnimations
})

export class Ashcorp_Field_Meta_Component {
  @Input() form: FormGroup;
  @Input() show?: boolean = true;

  public helper: SharedService;

  constructor(private _shared_service: SharedService) {
    this.helper = this._shared_service;
  }

  get meta_keys() {
    const metaGroup = this.form.get('meta') as FormGroup;
    return Object.keys(metaGroup.controls);
  }
}
