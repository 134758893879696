import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Breadcrumb } from 'app/core/navigation/breadcrumb.types';
import { company } from 'app/core/system/company.types';
import { environment } from 'environments/environment';

@Component({
    selector: 'manager-layout',
    templateUrl: './manager.layout.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default
})
export class ManagerLayoutComponent implements OnInit, OnDestroy {
    public cdn_url: string = environment.cdn_url;
    public api_url: string = environment.api_url;

    isScreenSmall: boolean;
    navigation: any = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public company: company;
    public branch: company;
    public breadcrumb: Breadcrumb;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigation_service: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {
        this._navigation_service.breadcrumb$.subscribe(
            (breadcrumb) => {
                this.breadcrumb = breadcrumb;
            }
        );
        this._navigation_service.company$.subscribe(
            (company) => {
                this.company = company;
            }
        );
        this._navigation_service.branch$.subscribe(
            (branch) => {
                this.branch = branch;
            }
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigation_service.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    public apply_filter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this._navigation_service.search(filterValue);
    }

    public go_to(url: string) {
        console.log(url);
        this._navigation_service.goto(url);
    }
}
