import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { SharedService } from 'app/shared/shared.service';
import { FormGroup } from '@angular/forms';
import moment, { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
  selector: 'ashcorp-field-month',
  templateUrl: './ashcorp-field-month.component.html',
  animations: fuseAnimations,
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
})

export class Ashcorp_Field_Month_Component {
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() name: string;
  @Input() date: number = 1;
  @Input() show?: boolean = true;

  public helper: SharedService;

  constructor(private _shared_service: SharedService) {
    this.helper = this._shared_service;
  }

  changeDatePicker() {
    let value = moment(this.form.get(this.name).value ?? new Date()).format('YYYY-MM');
    this.form.get(this.name).setValue(value + "-" + this.date);
  }

  setMonthAndYear(date: Moment, datepicker: MatDatepicker<Moment>) {
    this.changeDatePicker();
    datepicker.close();
  }
}