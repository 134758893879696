import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/core/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            // { path: 'unlock-session', loadChildren: () => import('app/core/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) },
            { path: 'confirmation-required', loadChildren: () => import('app/core/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            // { path: 'reset-password', loadChildren: () => import('app/core/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
        ]
    },

    // System routes
    {
        path: '',
        component: LayoutComponent,
        resolve: {
            // initialData: InitialDataResolver,
        },
        data: {
            layout: 'empty'
        },
        children: [
            // { path: 'comming-soon', pathMatch: 'full', loadChildren: () => import('app/modules/system/coming-soon/coming-soon.module').then(m => m.ComingSoonModule) },
            { path: 'maintenance', pathMatch: 'full', loadChildren: () => import('app/modules/system/maintenance/maintenance.module').then(m => m.MaintenanceModule) },
            { path: 'sign-in', loadChildren: () => import('app/core/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'forgot-password', loadChildren: () => import('app/core/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },

            // Error
            {
                path: 'error', children: [
                    { path: '404', loadChildren: () => import('app/modules/system/error/error-404/error-404.module').then(m => m.Error404Module) },
                    { path: '500', loadChildren: () => import('app/modules/system/error/error-500/error-500.module').then(m => m.Error500Module) }
                ]
            },
        ]
    },

    // Dashboard
    {
        path: ':branch',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        data: {
            layout: 'manager'
        },
        children: [
            { path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.dashboard_module) },
            { path: 'payroll', loadChildren: () => import('app/modules/payroll/payroll.module').then(m => m.payroll_module) },
            { path: 'point-of-sale', loadChildren: () => import('app/modules/point_of_sale/point_of_sale.module').then(m => m.point_of_sale_module) },
            { path: 'accounting', loadChildren: () => import('app/modules/accounting/accounting.module').then(m => m.accounting_module) }
        ]
    },

    // Catch all
    { path: '**', redirectTo: 'error/404' }
];
