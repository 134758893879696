import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { SharedService } from 'app/shared/shared.service';

@Component({
  selector: 'ashcorp-field-button',
  templateUrl: './ashcorp-field-button.component.html',
  animations: fuseAnimations
})

export class Ashcorp_Field_Button_Component {
  @Input() component: any;
  @Input() font: any;
  @Input() item: any;
  @Input() click: any;

  public helper: SharedService;

  constructor(private _shared_service: SharedService) {
    this.helper = this._shared_service;
  }

  public event(action: string, params: object) {
    this.component[action](params);
  }
}
