import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/auth/auth.service';
import { FormArray } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'app/core/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    constructor(
        private _authService: AuthService,
        private _userService: UserService
    ) { }

    private _search_key: BehaviorSubject<string> = new BehaviorSubject(null);
    get search_key$(): Observable<string> {
        return this._search_key.asObservable();
    }
    sKey(key: string): Observable<string> {
        if (key.length != 0) {
            this._search_key.next(key);
        }
        return this.search_key$;
    }

    tText(key: string, type: string = '') {
        if (this._authService.is_admin) {
            if (type != "") {
                type = "/" + type;
            }
            return environment.api_url + "manager/translate/" + key + type;
        }
        return "#";
    }

    tCKey(key: string, prefix: string = '') {
        prefix = this.slugify(prefix);
        key = this.slugify(key).replace(prefix + "_", "");
        return [prefix, key].join("_");
    }

    tClass() {
        return this._authService.is_admin;
    }

    tLog(x: any) {
        console.log(x);
    }



    FormArray(fa: any) {
        return fa as FormArray;
    }





    abs(number: number): number {
        return Math.abs(number);
    }

    slugify(text: string, join: string = "_") {
        return text.toLowerCase().split(" ").join(join).split("-").join(join).split("&").join(join);
    }

    unslugify(text: string, join: string = "_") {
        return text.split(join).join(" ").split("-").join(" ").replace(/(\w)(\w*)/g,
            function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
    }

    build_path(...paths: any[]) {
        return paths.join("/");
    }

    zero_or_modulo(number: number, base: number = 10) {
        return (number == 0) || (number % base == 0);
    }

    range(end: number, start: number = 0, step: number = 1) {
        return (Array.from({ length: end + 1 - start }, (_, i) => i + step - 1 + start));
    }

    is_active(value: string) {
        return value == 'ACTIVE';
    }
}