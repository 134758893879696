import { AfterViewInit, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { SharedService } from 'app/shared/shared.service';

@Component({
  selector: 'ashcorp-field-select',
  templateUrl: './ashcorp-field-select.component.html',
  animations: fuseAnimations
})

export class Ashcorp_Field_Select_Component implements AfterViewInit {
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() name: string;
  @Input() options: any[];
  @Input() show?: boolean = true;

  public selections: any[];

  public helper: SharedService;

  constructor(private _shared_service: SharedService) {
    this.helper = this._shared_service;
  }

  public ngAfterViewInit(): void {
    this.selections = this.options;
  }
}
