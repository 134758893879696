import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { Api_Response } from 'app/shared/shared.types';

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;
    private _is_admin: boolean = false;
    /**
     * Constructor
     */
    constructor(
        private _http_client: HttpClient,
        private _user_service: UserService
    ) {
        var token = localStorage.getItem('access_token');
        if (token != undefined && token != "undefined") {
            let t = AuthUtils._decodeToken(token);
            this._is_admin = t.role <= 1;
        }
    }

    set access_token(token: string) {
        localStorage.setItem('access_token', token);
    }

    get access_token(): string {
        var token = localStorage.getItem('access_token');
        if (token == 'undefined' || token == null) {
            localStorage.removeItem('access_token');
            return "";
        }
        return token;
    }

    get is_admin(): boolean {
        return this._is_admin;
    }


    forgot_password(email: string): Observable<any> {
        return this._http_client.post('api/auth/forgot-password', email);
    }

    reset_password(password: string): Observable<any> {
        return this._http_client.post('api/auth/reset-password', password);
    }

    sign_in(credentials: { email: string; password: string }): Observable<any> {
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._http_client.post('api/session/sign-in', credentials).pipe(
            switchMap((response: any) => {
                this.access_token = response.payload.access_token;
                this._authenticated = true;

                this._user_service.user = response.payload.user;
                this._is_admin = response.payload.user.role <= 1;
                return of(response);
            })
        );
    }

    sign_in_using_token(): Observable<any> {
        return this._http_client
            .post('api/session/refresh-access-token', {
                access_token: this.access_token,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: Api_Response<any>) => {
                    this.access_token = response.payload.access_token;
                    this._authenticated = true;

                    this._user_service.user = response.payload.user;
                    this._is_admin = response.payload.user.role <= 1;
                    return of(true);
                })
            );
    }

    sign_out(): Observable<any> {
        return this._http_client
            .post('api/session/sign-out', {
                access_token: this.access_token,
            })
            .pipe(
                switchMap((response: any) => {
                    localStorage.removeItem('access_token');

                    this._authenticated = false;
                    this._is_admin = false;
                    return of(true);
                })
            );
    }

    check(): Observable<boolean> {
        if (this._authenticated) {
            return of(true);
        }
        if (!this.access_token) {
            return of(false);
        }
        if (AuthUtils.isTokenExpired(this.access_token)) {
            return of(false);
        }
        return this.sign_in_using_token();
    }
}
