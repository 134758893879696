<div [formGroup]="form">
    <mat-form-field style="width:100%;">
        <mat-label *ngIf="show">{{label}}</mat-label>
        <input matInput [formControlName]="name" [placeholder]="label" [readonly]="readonly" #input>
        <button mat-icon-button matSuffix *ngIf="suffix" (click)="suffix.context[suffix.action](suffix)">
            <mat-icon [svgIcon]="suffix.icon"></mat-icon>
        </button>
        <mat-error *ngIf="form && form.get(name) && form.get(name).hasError('required')">
            {{label}} est <strong>requise</strong>
        </mat-error>
    </mat-form-field>
</div>