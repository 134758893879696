import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { SharedService } from "app/shared/shared.service";

import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { Ashcorp_Table } from "./ashcorp-table.types";
import { NavigationService } from "app/core/navigation/navigation.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component(
    {
        selector: 'ashcorp-table',
        templateUrl: './ashcorp-table.component.html',
        changeDetection: ChangeDetectionStrategy.Default,
        animations: [
            trigger('toggle', [
                state('close', style({ height: '0px', minHeight: '0', opacity: 0 })),
                state('open', style({ height: '*' })),
                transition('open <=> close', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            ]),
        ]
    }
)

export class Ashcorp_Table_Component<type> implements AfterViewInit {
    @Input() component: any;
    @Input() table: Ashcorp_Table<type>;
    @Input() more?: any;

    @ViewChild(MatSort) sortor: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    constructor(public _: NavigationService, public _shared_service: SharedService) { }

    ngAfterViewInit(): void {
        this.table.data_display.sort = this.sortor;
        this.table.data_display.paginator = this.paginator;

        combineLatest([
            this._.search$,
            this.table.filter$
        ]).pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(
            ([s, f]) => {
                let data = this.table.data_default;
                if (f.length > 0) {
                    data = data.filter(item => {
                        let fit = true;
                        f.forEach((c: any) => {
                            if (c.action == "==" && item[c.key] != c.value) {
                                fit = false;
                                return;
                            } else if (c.action == "!=" && item[c.key] == c.value) {
                                fit = false;
                                return;
                            }
                        });
                        return fit;
                    });
                }
                this.table.data_display.data = data;
                if (s.length >= 0) {
                    this.table.data_display.filter = s.trim().toLowerCase();
                }
            }
        );

        this._.search("");
    }

    tracker(index: number, item: any): any {
        return item.id || index;
    }

    event(action: Function, params: any, idx: number, item: any) {
        this.component[action.name](params, idx, item);
    }

    format_header(header: string) {
        return header;
        // return this.table.format.header[header] ?? header;
    }

    format_content(header: string, row: type) {
        let key = this.table.mapping_header[header] ?? header;
        let keys = key.split(".");
        keys.forEach((k: string) => {
            row = row[k];
        });
        return row;
    }

    // public format_data(type: string, value: any) {
    //     switch (type) {
    //         case "currency":
    //             value = (new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2, })).format(value);
    //             break;
    //         case "date":
    //             const dateString = value;
    //             const parts = dateString.split(/[- :]/);
    //             const dateObject = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
    //             value = (new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })).format(dateObject);
    //             break;
    //     }
    //     return value;
    // }
}