import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from '@angular/material/table';

import { Ashcorp_Button_Component } from 'app/components/ashcorp-button/ashcorp-button.component';
import { Ashcorp_Table_Component } from 'app/components/ashcorp-table/ashcorp-table.component';
import { Ashcorp_Field_Date_Component } from 'app/components/ashcorp-field/ashcorp-field-date/ashcorp-field-date.component';
import { Ashcorp_Field_Select_Component } from 'app/components/ashcorp-field/ashcorp-field-select/ashcorp-field-select.component';
import { Ashcorp_Field_Text_Component } from 'app/components/ashcorp-field/ashcorp-field-text/ashcorp-field-text.component';
import { Ashcorp_Field_Button_Component } from 'app/components/ashcorp-field/ashcorp-field-button/ashcorp-field-button.component';
import { Ashcorp_Field_Seperator_Component } from 'app/components/ashcorp-field/ashcorp-field-seperator/ashcorp-field-seperator.component';
import { Ashcorp_Field_Number_Component } from 'app/components/ashcorp-field/ashcorp-field-number/ashcorp-field-number.component';
import { Ashcorp_Field_TextArea_Component } from 'app/components/ashcorp-field/ashcorp-field-textarea/ashcorp-field-textarea.component';
import { Ashcorp_Field_Month_Component } from 'app/components/ashcorp-field/ashcorp-field-month/ashcorp-field-month.component';
import { Ashcorp_Field_Meta_Component } from 'app/components/ashcorp-field/ashcorp-field-meta/ashcorp-field-meta.component';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [
        Ashcorp_Button_Component,
        Ashcorp_Table_Component,

        Ashcorp_Field_Text_Component,
        Ashcorp_Field_TextArea_Component,
        Ashcorp_Field_Number_Component,
        Ashcorp_Field_Date_Component,
        Ashcorp_Field_Month_Component,
        Ashcorp_Field_Select_Component,
        Ashcorp_Field_Button_Component,

        Ashcorp_Field_Meta_Component,
        Ashcorp_Field_Seperator_Component,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslocoModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatSelectModule,
        MatCheckboxModule,
        MatIconModule,
        MatTableModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatButtonModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        Ashcorp_Button_Component,
        Ashcorp_Table_Component,

        Ashcorp_Field_Text_Component,
        Ashcorp_Field_TextArea_Component,
        Ashcorp_Field_Number_Component,
        Ashcorp_Field_Date_Component,
        Ashcorp_Field_Month_Component,
        Ashcorp_Field_Select_Component,
        Ashcorp_Field_Button_Component,

        Ashcorp_Field_Meta_Component,
        Ashcorp_Field_Seperator_Component,
    ],
    providers: [

    ]
})
export class SharedModule {
}