<div *ngIf="table" class="items-center justify-between w-full">
    <div class="xl:col-span-1 flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden">
        <div class="grid grid-cols-4 gap-4 p-6">
            <div class="col-span-2">
                <mat-icon *ngIf="table.icon" class="icon-size-6" [svgIcon]="table.icon"></mat-icon>
                <div class="text-lg font-medium tracking-tight leading-6 truncate">
                    {{table.title}}
                    <ng-container *ngFor="let filter of table.filter_table">
                        <button matripple class="mx-1" *ngIf="_.access(filter.access)"
                                (click)="event(filter.click,filter.params,i,row);"
                                [ngClass]="filter.class ?? 'mat-stroked-button'">
                            <mat-icon class="icon-size-6" [svgIcon]="filter.icon"></mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>
            <div class="col-span-2 text-right" *ngIf="table.action_table">
                <div class="text-lg font-medium tracking-tight leading-6 truncate">
                    <ng-container *ngFor="let action of table.action_table">
                        <button matripple class="mx-1" *ngIf="_.access(action.access)"
                                (click)="event(action.click,action.params,i,row);"
                                [ngClass]="action.class ?? 'mat-stroked-button'">
                            <mat-icon class="icon-size-6" [svgIcon]="action.icon"></mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto mx-6">

            <table class="w-full bg-transparent" mat-table matSort [dataSource]="table.data_display"
                   [trackBy]="table.tracker" #viewTable multiTemplateDataRows>

                <ng-container *ngFor="let header of table.header" [matColumnDef]="header">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        {{header}}
                    </th>
                    <td mat-cell *matCellDef="let row;let i = dataIndex;">
                        <div [@toggle]="i === table.more_idx ? 'close' : 'open'">
                            <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap" [ngClass]="">
                                {{format_content(header,row)}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container *ngIf="table.action_row" matColumnDef="Actions">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row;let i = dataIndex">
                        <div [@toggle]="i === table.more_idx ? 'close' : 'open'">
                            <ng-container *ngFor="let action of table.action_row">
                                <button matripple class="mx-1" *ngIf="_.access(action.access) && i !== table.more_idx"
                                        (click)="event(action.click,action.params,i,row);"
                                        [ngClass]="action.class ?? 'mat-stroked-button'">
                                    <mat-icon class="icon-size-6" [svgIcon]="action.icon"></mat-icon>
                                </button>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="more">
                    <td mat-cell *matCellDef="let row;let i = dataIndex;" [attr.colspan]="table.full_header.length">
                        <div [@toggle]="i === table.more_idx ? 'open' : 'close'">
                            <ng-container *ngTemplateOutlet="more"></ng-container>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="table.full_header"></tr>
                <tr mat-row *matRowDef="let row; columns: table.full_header;" style="height:0px;"></tr>
                <tr mat-row *matRowDef="let row; columns: ['more'];" style="height:0px;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="table.sizes" (page)="table.more()" showFirstLastButtons></mat-paginator>

        </div>
    </div>
</div>