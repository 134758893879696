import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from 'app/core/auth/auth.module';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { environment } from 'environments/environment';

@NgModule({
    imports: [
        AuthModule,
        IconsModule,
        TranslocoCoreModule
    ]
})
export class CoreModule {
    public ws_url: string = environment.websocket.url;
    public ws_realm: string = environment.websocket.realm;

    /**
     * Constructor
     */
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }

        const connection = new WebSocket(this.ws_url);
        let realm = this.ws_realm;

        connection.onopen = function () {
            var token = localStorage.getItem('access_token');
            var content = JSON.stringify({ method: "auth", realm: realm, token: token });
            connection.send(content);
        };

        connection.onerror = function (error) {
            console.error('WebSocket Error:', error);
        };

        connection.onmessage = function (event) {
            console.log(event.data);
        };
    }
}
