import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

    constructor(
        @Inject('BASE_API_URL') private baseUrl: string) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes("api/")) {
            let baseUrl = (this.baseUrl + request.url).replace("/./", "/");
            const apiReq = request.clone({ url: `${baseUrl}` });
            return next.handle(apiReq);
        }
        // if (request.url.includes("assets/")) {
        //     this.baseUrl = (window["cdn"] + "/" + request.url).replace("/./", "/");
        //     const apiReq = request.clone({ url: `${this.baseUrl}` });
        //     return next.handle(apiReq);
        // }
        const apiReq = request.clone({ url: `${request.url}` });
        return next.handle(apiReq);
    }
}